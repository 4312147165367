<template>
  <div class="flex-1">
    <form @submit="handleSearch">
      <div class="flex flex-col md:flex-row  items-center gap-4">
        <label :for="name" class="text-inherit flex-[0_0_25%] font-semibold mb-0" :class="labelClasses">
          {{label}}
        </label>
        <div class="w-full md:w-auto input-group" :class="inputGroupClasses">
          <input type="search"
                 :name="name"
                 :placeholder="placeholder"
                 class="w-full  lg:w-[30rem] disabled:pointer-events-none disabled:bg-grey-200/50"
                 :class="inputClasses"
                 v-model="searchQuery"
                 :disabled="isSearching"
          />
          <Button type="submit" theme="secondary" :is-loading="isSearching" :class="submitBtnClasses">
            {{ submitBtnLabel }}
            <SearchIcon v-if="showSearchIcon" class="w-6"/>
          </Button>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
  import { useRoute, useRouter } from "vue-router";
  import { useSearch } from "@/composables/useSearch";
  import Button from "~/components/global/Buttons/Button.vue";
  import SearchIcon from "~/components/global/Icons/SearchIcon.vue";
  const { searchQuery } = useSearch();

  const route = useRoute();
  const router = useRouter();

  const emit = defineEmits(['search']);

  const props = defineProps({
    placeholder: {
      default: 'Enter title or keyword here',
      type: String
    },
    value: {
      default: '',
      type: String
    },
    label: {
      default: 'Search',
      type: String
    },
    name: {
      default: 'search',
      type: String
    },
    isSearching: {
      default: false,
      type: Boolean
    },
    labelClasses: {
      default: '',
      type: String
    },
    inputGroupClasses: {
      default: '',
      type: String
    },
    inputClasses: {
      default: '',
      type: String
    },
    submitBtnLabel: {
      default: 'Submit',
      type: String
    },
    submitBtnClasses: {
      default: '',
      type: String
    },
    showSearchIcon: {
      default: false,
      type: Boolean
    }
  });

  function handleSearch(event: Event) {
    event.preventDefault();

    if (searchQuery.value === '' && route.query.q === '') {
      return;
    }

    router.replace({
      query: { ...route.query, q: searchQuery.value }
    });

    emit('search', searchQuery.value);
  }
</script>
