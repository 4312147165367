import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
export function useSearch() {

  const searchQuery = ref('')
  const route = useRoute();

  // Set the initial value of searchQuery from the URL query parameter
  onMounted(() => {
    if (route.query.q) {
      searchQuery.value = route.query.q.toString();
    }
  });

  return {
    searchQuery,
  };
}
